html, body {
  height: 100%;
  margin: 0;
  background-color:rgb(43, 43, 43);
}
body {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: white;
}

#root {
  min-height: 100%; 
  background-color: rgb(43, 43, 43);
}

.search-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.search-button:hover {
  background-color: #45a049;
}

.title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;

}
